import { TextField, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { extractImageValue, extractTextAnswerValue } from '../../store/form.answer.extract';
import { textAnswer } from '../../store/form.answer.fill';
import { openSectionState, currentSectionState } from '../../store/form.store';
import { ImageComponent } from '../../../components/ImageComponent';

class MultiLineInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            imageValue: [],
            editable: false
        };
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {expanded = sectionId});
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded})
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractTextAnswerValue(answer);
            if (userResponse) {
                this.setState({
                    value: userResponse
                });
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }
        }
    }

    onChange = (event) => {
        this.setState({ value: event.target.value})
        let answerNode = textAnswer(event.target.value, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.editable ? (
                    <TextField
                        id="textField" fullWidth required variant="outlined"
                        multiline
                        minRows={4}
                        error={this.props.hasError}
                        value={this.state.value}
                        onChange={this.onChange}
                    />
                    ) : (<Typography>{this.state.value}</Typography>)}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue.length > 0) ? <ImageComponent imageList={this.state.imageValue} /> : null}
            </Fragment>
        );
    }
}
export default MultiLineInput;