import { Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { extractDateHoursValue, extractImageValue } from '../../store/form.answer.extract';
import { withStyles } from '@material-ui/styles';
import { currentSectionState, openSectionState } from '../../store/form.store';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { dateAnswer, hoursAnswer } from '../../store/form.answer.fill';
import moment from 'moment';
import HoursWorkedInput from './HoursWorkedInput';
import { DATE_TIME_FORMATS } from '../../../constants/Constants';
import { ImageComponent } from '../../../components/ImageComponent';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
    upperText:{
        textTransform: 'uppercase',
    },
    nowrap:{
        whiteSpace: 'nowrap',
    }
});

class DateTimeElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            hoursWorked: '',
            imageValue: [],
            editable: false,
            dateFormat: 'dd/MM/yyyy',
            dateClicked: false,
            isShowDateTimePicker: false
        };
        this.dateFormat = '';
        this.formatted_date_format = '';
        this.moment_date_format = '';
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => { expanded = sectionId });
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded })
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.hasHelpImage) {
            this.onLoadImage(this.props);
        }
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
    }

    onLoadPage(props) {
        this.dateFormat = this.updatedDateFormat(this.props.element.validations.dateFormat);
        this.formatted_date_format = this.dateFormat.formatted_date_format;
        this.moment_date_format = this.dateFormat.moment_date_format;
        if (props.element.validations.dateFormat) {
            this.setState({
                dateClicked: true,
                dateFormat: this.dateFormat.formatted_date_format,
            });
            if (props.element.validations.dateFormat.toString().includes('h')) {
                this.setState({
                    isShowDateTimePicker: true
                });
            }
            else {
                this.setState({
                    isShowDateTimePicker: false
                });
            }
        }
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractDateHoursValue(answer);
            if (userResponse) {
                let date = userResponse.date;
                if (date) {
                    this.setState({
                        date: date,
                        selectedDate: props.element.validations.dateFormat.toString().includes('h') ? new Date(moment(date, this.moment_date_format).format("MM/DD/YY h:mm")) : new Date(moment(date, this.moment_date_format).format("MM/DD/YYYY")),
                        dateClicked: true,
                        dateFormat: this.dateFormat.formatted_date_format,
                    });
                }
                let hours = userResponse.hours;
                if (hours) {
                    this.setState({
                        hoursWorked: hours
                    });
                }
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }     
        }
    }


    onChange = (event) => {
        let formattedDate = new Date(moment(event, this.moment_date_format).format("MM/DD/YY HH:mm"));
        let updatedDate = moment(event).format(this.moment_date_format).toString();
        this.setState({
            dateClicked: true,
            selectedDate: formattedDate,
            dateFormat: this.formatted_date_format
        })
        let answerNode = dateAnswer(updatedDate, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }


    updatedDateFormat = (dateFormat) => {
        let momentFormat = '';
        switch (dateFormat) {
            case DATE_TIME_FORMATS.DD_MM_YY:
                dateFormat = DATE_TIME_FORMATS.DD_MM_YY_SMALL;  //to display the format in date picker
                momentFormat = DATE_TIME_FORMATS.DD_MM_YY; // to send the selected date with the required format in dateAnswer functionality
                break;
            case DATE_TIME_FORMATS.DD_MM_YYYY:
                dateFormat = DATE_TIME_FORMATS.DD_MM_YYYY_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MM_YYYY;
                break;
            case DATE_TIME_FORMATS.MM_DD_YY:
                dateFormat = DATE_TIME_FORMATS.MM_DD_YY_SMALL;
                momentFormat = DATE_TIME_FORMATS.MM_DD_YY;
                break;
            case DATE_TIME_FORMATS.MM_DD_YYYY:
                dateFormat = DATE_TIME_FORMATS.MM_DD_YYYY_SMALL;
                momentFormat = DATE_TIME_FORMATS.MM_DD_YYYY;
                break;
            case DATE_TIME_FORMATS.DD_MM_YYYY_H_MM_SS:
                dateFormat = DATE_TIME_FORMATS.DD_MM_YYYY_H_MM_SS_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MM_YYYY_H_MM_SS;
                break;
            case DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS:
                dateFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS_SMALL;
                momentFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS;
                break;
            case DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS_A:
                dateFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS_A;
                break;
            case DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_A:
                dateFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_A;
                break;
            case DATE_TIME_FORMATS.DD_MMM_YYYY:
                dateFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MMM_YYYY;
                break;
            case DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS:
                dateFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS;
                break;
            case DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS_A:
                dateFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS_A;
                break;
            case DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_A:
                dateFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_A;
                break;
            case DATE_TIME_FORMATS.YYYY_MM_DD_HH_MM_SS_SSSZ:
                dateFormat = DATE_TIME_FORMATS.YYYY_MM_DD_HH_MM_SS_SSSZ_SMALL;
                momentFormat = DATE_TIME_FORMATS.YYYY_MM_DD_HH_MM_SS_SSSZ;
                break;
            case DATE_TIME_FORMATS.DD_MMM_YYYY_HH_MM_SS_A:
                dateFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_HH_MM_SS_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MMM_YYYY_HH_MM_SS_A;
                break;
            case DATE_TIME_FORMATS.MM_DD_YYYY_HH_MM_A:
                dateFormat = DATE_TIME_FORMATS.MM_DD_YYYY_HH_MM_A_SMALL;
                momentFormat = DATE_TIME_FORMATS.MM_DD_YYYY_HH_MM_A;
                break;
            default:
                dateFormat = DATE_TIME_FORMATS.DD_MM_YY_SMALL;
                momentFormat = DATE_TIME_FORMATS.DD_MM_YY;
        }
        let formatted_date_format = dateFormat;
        let moment_date_format = momentFormat;
        return { formatted_date_format, moment_date_format };
    }

    didUpdateState = () => {
        let answerNode = hoursAnswer(this.state.hoursWorked, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    onChangeHoursWorked = (value) => {
        this.setState({ hoursWorked: value }, () => {
            this.didUpdateState();
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.state.editable ?
                    (this.state.isShowDateTimePicker ?
                        <DateTimePicker
                            label={this.props.element.validations.dateFormat}
                            value={this.props.element?.answer ? this.state.selectedDate : null}
                            onChange={this.onChange}
                            helperText={''}
                            error={false}
                            format={this.state.dateClicked ? this.state.dateFormat : 'dd/MM/yyyy HH:mm'}
                            className={classes.nowrap}
                        />:
                        <DatePicker
                            label={this.props.element.validations.dateFormat}
                            value={this.props.element?.answer ? this.state.selectedDate : null}
                            onChange={this.onChange}
                            helperText={''}
                            error={false}
                            format={this.state.dateClicked ? this.state.dateFormat : 'dd/MM/yyyy'}
                            className={classes.nowrap}
                        />)
                    : (<Typography className={classes.upperText}>{this.state.date}</Typography>)}
                <br />
                {this.state.editable && this.props.element.properties.hasHoursWorked?
                    <HoursWorkedInput
                        defaultValue={this.state.hoursWorked}
                        elementId={this.props.element.elementId}
                        onChange={this.onChangeHoursWorked}
                    />
                    : <Typography>{(Number.parseInt(this.state.hoursWorked, 10) >= 0) ? "Hour(s) Worked " + this.state.hoursWorked : null}</Typography>}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue?.length>0) ? <ImageComponent imageList={this.state.imageValue} /> : null}
            </Fragment>);
    }
}
export default withStyles(styles)(DateTimeElement);