import React, { useEffect, useState } from 'react'
import API from "../services/http-helper.service";
import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import ImagePopup from '../review-portal/components/form-element/ImagePopup';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginTop: 0,
  },
}));

export function ImageComponent({ imageList }) {
  const [imageDataList, setImageDataList] = useState([]);
  const [imageOpen, setImageOpen] = React.useState(false);
  const [imageDetails, setImageDetails] = useState({})
  const classes = useStyles();

  useEffect(() => {
    if (imageList?.length > 0) {
      imageList.forEach((val) => getFormImage(val.imageId))
    }
  }, [imageList]);

  const getFormImage = async (id) => {
    try {
      let res = await API.get(`images/${id}`);
      if (res.status === 200) {
        setImageDataList((prevData) => {
          return [
            ...prevData,
            {
              fileUrl: res?.data?.attachments?.[0]?.file || '',
              fileName: res?.data?.attachments?.[0]?.fileName || ''
            },
          ];
        });
      }
    } catch (e) {
      return null;
    }
  };

  const onClickAvatar = (item) => {
    setImageDetails(item)
    setImageOpen(true);
  }

  const onClose = () => {
    setImageOpen(false);
  }
  return (
    <>
      {imageList?.length > 0 && imageDataList?.length > 0 ?
        <Card className={classes.root} >
          <Box>
            <Grid container spacing={2}>
              {imageDataList.map((val, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <img src={val.fileUrl} alt={`image-${index + 1}`} style={{ height: '75px', width: '100px', cursor: 'pointer' }} onClick={() => onClickAvatar(val)} />
                </Grid>
              ))}
              <ImagePopup open={imageOpen} onClose={onClose} imageUrl={imageDetails.fileUrl} title={imageDetails.fileName} />
            </Grid>
          </Box>
        </Card> : null}
    </>
  );
}
