import React, { Component, Fragment } from 'react';
import { extractImageValue, extractRangeValue } from '../../store/form.answer.extract';
import { Slider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { currentSectionState, openSectionState } from '../../store/form.store';
import { rangeAnswer } from '../../store/form.answer.fill';
import { ImageComponent } from '../../../components/ImageComponent';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
        width: 'fit-content',
    },
    labelContainer: {
        flexDirection: 'row',
        width: '100%',
    },
    minLabel: {
        marginLeft: '-2%',
        marginTop: '-7%',
        float: 'left',
    },
    maxLabel: {
        float: 'right',
        marginRight: '-2%',
        marginTop: '-7%',
    },
});

class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false
        };
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => { expanded = sectionId });
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded })
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractRangeValue(answer);
            if (userResponse) {
                this.setState({
                    value: userResponse
                });
            }
            else {
                this.setState({
                    value: this.props.element.properties.minRange
                })
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }
        }
    }

    onChange = (event, value) => {
        this.setState({ value: value})
        let answerNode = rangeAnswer(value);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            {this.state.editable ? (
                <Fragment>
                    <Slider
                        value={Number.parseInt(this.state.value, 10)}
                        onChange={(event, value) => this.onChange(event, value)}
                        valueLabelDisplay="auto"
                        step={Number.parseInt(this.props.element.properties.rangeMultiplier, 10)}
                        marks
                        min={Number.parseInt(this.props.element.properties.minRange, 10)}
                        max={Number.parseInt(this.props.element.properties.maxRange, 10)}
                    />
                    <span className={classes.labelContainer}>
                        {this.props.element.properties.minRange && (
                            <Typography className={classes.minLabel}>{Number.parseInt(this.props.element.properties.minRange, 10)}</Typography>
                        )}
                        {this.props.element.properties.maxRange && (
                            <Typography className={classes.maxLabel}>{Number.parseInt(this.props.element.properties.maxRange, 10)}</Typography>
                        )}
                    </span></Fragment>) :
                (<Typography>{Number.parseInt(this.state.value, 10)}</Typography>)}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue.length > 0) ? <ImageComponent imageList={this.state.imageValue}/> : null}
        </Fragment>);
    }
}

export default withStyles(styles)(RangeSlider);

