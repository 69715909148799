import React, { Fragment } from "react";
import { Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { FormStore, FORM_STATUS } from "../../services/form.store";
import { messages, GeneralConfig } from "../../utility/Constant";
import FormBuilderService from "../../services/form-builder.service";
import { toast } from "react-toastify";
import _ from "lodash";
import { Loader } from "../Loader";
import AlertDialog from "./AlertDialog";
import { ALERT_DIALOGS } from "../../services/form.helper.service";
import { ImageComponent } from "../ImageComponent";
const styles = (theme) => ({
  thumbnailGroup: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "fit-content",
    position: "relative",
  },
  thumbnailInner: {
    display: "inline-block",
    width: "190px",
    paddingBottom: "7px",
    marginRight: "10px"
  },
  errorExcessImagesUpload: {
    width: "100%",
  },
  error: {
    color: 'red',
    padding: '4px',
    fontSize: 14,
    width: '-webkit-fill-available'
    },
  UploadButton: {
    display: "none",
  },
  helpAddImageButton: {
    marginTop: "10px",
    color: "#757a76",
    border: "1px solid gray",
  },
  removeImageIcon: {
    position: "relative",
    left: "168px",
    top: "13px",
    display: "block",
    color: "red",
    backgroundColor: "white",
    borderRadius: "50%",
    cursor: "pointer",
  },
  loaderBoxMainGroup:{
    height: "120px"
  },
  loaderBoxGroup:{
    width: "calc(100% + 160px)",
    marginTop: "40px",
    marginLeft: "0",
    position: "absolute",
  },
  loaderBox: {
    position: "relative",
    width: "20px",
    height: "20px",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "90px",
    "& > .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
    },
  },
});

class HelpImagesGroup extends React.Component {
  constructor(props) {
    super();
    this.FormBuilderService = new FormBuilderService();
    this.state = {
      files: props?.propsimagesData && (!props.propsimagesData.includes(null)) ? props?.propsimagesData : [],
      isLoading: false,
      showDeleteConfirmDialog: false,
      imageDeleteId: null
    };
  }

  uploadFile = (e) => {
    let originalfileObj = e.target.files[0];
    let splitExtension = originalfileObj.name.split(/\.(?=[^\.]+$)/);
    let newFileName = splitExtension[0].replace(/[\W_]/g, "_")+'.'+splitExtension[1];
    const imgBlob = new Blob([originalfileObj], { type: originalfileObj.type });
    const file = new File([imgBlob], newFileName, { type: 'image/'+splitExtension[1], lastModified: originalfileObj.lastModified });
    let files = this.state.files;
    let id = "";
    let fileName = file.name;
    let fileNotAvailable = _.filter(
      files,
      (file) => file.imageName === fileName
    );
    if (fileNotAvailable.length === 0) {
      id = this.generateImageId();
      let fileObj = {
        imageName: fileName,
        imageUrl: URL.createObjectURL(file),
        imageId: id,
        file: file,
      };
      this.FormBuilderService.uploadImageDetails(fileObj)
      .then(
        this.setState({
          isLoading: true,
        })
      )
      .then((response) => {
        document.getElementById("contained-button-file").value = "";
        document.getElementById("contained-button-file").type = "";
        document.getElementById("contained-button-file").type = "file";
        this.setState({
          isLoading: false
        })
        if (response === undefined)
          toast.error(ALERT_DIALOGS.ADD_IMAGE_UNDEFINED_ERROR_MESSAGE);
        else {
          fileObj.imageUrl = response.attachments[0].file;
          delete fileObj.file;
          let images = FormStore.sharedInstance().addHelpImage(fileObj);
          this.setState({ files: images });
          toast.success(ALERT_DIALOGS.ADD_IMAGE_SUCCESS_MESSAGE);
        }
      });
    } else toast.error("Image " + originalfileObj.name + " already exists.");
  };

  generateImageId() {
    return (
      (this.props?.elementHasId !== undefined
        ? this.props?.elementHasId
        : new Date().getFullYear()) +
      "-" +
      Date.now() +
      "-" +
      this.state.files.length
    );
  }

  confirmDelete = () => {
    this.setState({
      showDeleteConfirmDialog: true,
    })
  }

  handleCancelDelete = () => {
    this.setState({
      showDeleteConfirmDialog: false
    })
  }

  handleConfirmDelete = () => {
    this.removeImage(this.state.imageDeleteId)
  }

  removeImage = (id) => {
    this.FormBuilderService.imageDetails(id, GeneralConfig.DELETE).then(
      (response) => {
        if (response && response.status && response.status === 200) {
          let images = FormStore.sharedInstance().removeHelpImage(
            response.data.imageId
          );
          toast.success(ALERT_DIALOGS.DELETE_IMAGE_SUCCESS_MESSAGE);
          this.setState({ files: images });
        } else {
          toast.error(ALERT_DIALOGS.DELETE_IMAGE_ERROR_MESSAGE);
        }
      }
    ).then(
      ()=>{
        this.setState({
          imageDeleteId: null,
          showDeleteConfirmDialog: false
        })
         FormStore.sharedInstance().SaveAsDraft(FORM_STATUS.DRAFT);
      })
  };

  render() {
    const { classes } = this.props;
    const { files } = this.state;
    return (
      <Fragment>
        <Box className={classes.box} pb={5} pl={3} pr={1}>
          <div className={classes.thumbnailGroup}>
          {this.state.isLoading ? <div className={classes.loaderBoxMainGroup}>
            <div className={classes.loaderBoxGroup}>
              <div className={classes.loaderBox}>
                <Loader position="center" loading={this.state.isLoading} />
              </div>
            </div>
          </div> : null}
            {files.map((item, index) => item && (
              (index < GeneralConfig.MAX_IMAGE_COUNT) ? (
                <div className={classes.thumbnail} key={index}>
                  <div className={classes.thumbnailInner}>
                    <RemoveCircleOutlineOutlinedIcon
                      className={classes.removeImageIcon}
                      id="helpDeleteImageButton"
                      onClick={()=>{
                        this.confirmDelete();
                        this.setState({imageDeleteId: item.imageId})
                      }}
                    />
                    <img src={item.imageUrl} alt={`Help-${item.imageId}`} height="120" width="180" />
                  </div>
                </div>
              ) : null)
            )}
          </div>
          {files.length === GeneralConfig.MAX_IMAGE_COUNT ? (
              <div className={classes.errorExcessImagesUpload}>
                <label className={classes.error}>
                  {messages.CANNOT_UPLOAD_MORE_IMAGES}
                </label>
              </div>
            ) : null}
          <input
            accept="image/*"
            type="file"
            className={classes.UploadButton}
            id="contained-button-file"
            onChange={this.uploadFile}
            disabled={this.state.files.length === 3}
          />
          <div className={classes.imageButton}>
            <label htmlFor="contained-button-file">
              <Button
                variant="contained outlined"
                id="helpAddImageButton"
                component="span"
                className={classes.helpAddImageButton}
                startIcon={<AddPhotoAlternateOutlinedIcon />}
              >
                Add Image
              </Button>
            </label>
          </div>
        </Box>
        {(this.state.showDeleteConfirmDialog) ?
            <AlertDialog
              open={this.state.showDeleteConfirmDialog}
                onClose={this.handleCancelDelete} handleOk={this.handleConfirmDelete}
                dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                dialogContent={ALERT_DIALOGS.DELETE_IMAGE_CONTENT}
                cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
            /> : null}
      </Fragment>
    );
  }
}
export default withStyles(styles)(HelpImagesGroup);