import React, { useEffect, useState } from 'react'
import API from "../../../services/http-helper.service";
import { Grid } from '@material-ui/core';
import ImagePopup from './ImagePopup';


export function ImageComponent(props) {
    const [imageList, setImageList] = useState([]);
    const [imageOpen, setImageOpen] = React.useState(false);
    const [imageDetails, setImageDetails] = useState({})

    useEffect(() => {
        if (props?.element?.imagesData?.length > 0) {
            props.element.imagesData.map((val) => getFormImage(val.imageId))
        }
    }, []);

    const getFormImage = async (id) => {
        try {
            let res = await API.get(`images/${id}`);
            if (res.status === 200) {
                setImageList((prevData) => {
                    return [
                        ...prevData,
                        {
                            fileUrl: res?.data?.attachments?.[0]?.file || '',
                            fileName: res?.data?.attachments?.[0]?.fileName || ''
                        },
                    ];
                });


            }
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    const onClickAvatar = (item) => {
        setImageDetails(item)
        setImageOpen(true);
    }

    const onClose = () => {
        setImageOpen(false);
    }

    return (
        <Grid container spacing={2}>
            {imageList?.map((val, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <img {...props} src={val.fileUrl} alt={`image-${index}`} style={{ height: '75px', width: '100px' }} onClick={() => onClickAvatar(val)} />
                </Grid>
            ))}
            <ImagePopup open={imageOpen} onClose={onClose} imageUrl={imageDetails.fileUrl} title={imageDetails.fileName} />
        </Grid>
    );
}
