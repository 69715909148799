import React, { Component } from 'react';
import { ImageComponent } from '../../../components/ImageComponent';
class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageValue: [],
        };
    }
    componentDidMount() {
        this.onLoadImage(this.props);
    }

    onLoadImage(props) {
        if (props.element) {
            let answerJson = typeof props.element?.answer === "string" ? JSON.parse(props.element?.answer) : props.element?.answer
            if (answerJson?.image) {
                this.setState({ imageValue: answerJson.image});
            }
        }
    }

    render() {
        return <ImageComponent imageList={this.state.imageValue} />;
    }
}
export default FileUploader;